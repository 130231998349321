<template>
  <div ref="pdfContent">
    <b-row>
      <b-col class="float-left">
        <h3>{{ userInfo.name }}</h3>
        <div v-if="generalInformation">
          <p class="card-text mb-25">
            <label class="font-weight-bold">{{ $t('Email') }}:</label> {{ basicUserInfo.email }}
          </p>
          <p class="card-text mb-25">
            <label class="font-weight-bold">{{ $t('Address') }}:</label> {{ generalInformation.streetAddress }}, {{ generalInformation.district }},  {{ generalInformation.country.name }}
          </p>
          <p class="card-text mb-0">
            <label class="font-weight-bold">{{ $t('Mobile') }}:</label>  {{ generalInformation.mobileNumber }}, {{ generalInformation.mobileNumber2 }}
          </p>
        </div>
        <div v-if="bankingInformation">
          <p class="card-text mb-25">
            <label class="font-weight-bold">{{ $t('Bank Name') }}:</label> {{ bankingInformation.bankName }}<br>
            <label class="font-weight-bold">{{ $t('Account Number') }}:</label> {{ bankingInformation.bankAccount }}<br>
            <label class="font-weight-bold">{{ $t('Bank Address') }}:</label>  {{ bankingInformation.bankAddress }}<br>
            <label class="font-weight-bold">{{ $t('Swift Code') }}:</label>{{ bankingInformation.bankSwiftCode }}
          </p>
        </div>
      </b-col>
      <b-col class="text-right">
        <h3>{{ $t('Invoice') }}</h3>
        <div>
          <p class="card-text mb-25">
            {{ $t('Date') }}: {{ FORMAT_DATE(dateRange.start_date, COMMON.DATE_FORMAT) }} - {{ FORMAT_DATE((dateRange.end_date), COMMON.DATE_FORMAT) }}
          </p>
          <p class="card-text mb-25 text-muted">
            {{ $t('Due Date: within 7 days of this invoice') }}
          </p>
        </div>
      </b-col>
    </b-row>

    <b-form-group
      class="w-50 mt-2"
    >
      <template #label>
        {{ $t('Source Account') }}
        <feather-icon
          v-b-tooltip.hover="$t('payments.source-account-tooltip')"
          icon="InfoIcon"
        />
      </template>
      <treeselect
        :options="altAccountsList"
        :disable-branch-nodes="true"
        :placeholder="$t('payments.select-source-account')"
        @select="getWalletFromTree"
      >
        <label
          slot="option-label"
          slot-scope="{ node, labelClassName }"
          :class="labelClassName"
        >
          <div v-if="!node.isBranch">
            <i
              :class="getWalletIcon(node.raw.type)"
              class="fa"
            />
            {{ node.label }}
          </div>
          <span v-else>{{ node.label }}</span>
        </label>
      </treeselect>
    </b-form-group>

    <div class="pt-2">
      <b-table-simple
        class="table table-sm table-hover table-striped table-bordered"
        responsive
      >
        <thead>
          <tr>
            <th class="bg-primary text-white">
              {{ $t('Description') }}
            </th>
            <th class="bg-primary text-white">
              {{ $t('Allocation') }}
            </th>
            <th class="bg-primary text-white text-right">
              {{ $t('Amount') }}
            </th>
            <th class="bg-primary text-white text-right">
              {{ $t('Remarks') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="240px">
              <b-form-input
                v-model="description"
                type="text"
                @change="$emit('memo', description)"
              />
            </td>
            <td>
              <validation-provider
                #default="{ errors }"
                name="allocation"
                rules="required"
              >
                <v-select
                  v-model="payrollWalletUid"
                  :options="allocationsList"
                  :reduce="allocation => allocation.value"
                  :clearable="false"
                  :placeholder="$t('payments.select-allocation')"
                  @input="$emit('payroll-wallet-updated', payrollWalletUid)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </td>
            <td class="text-right">
              <strong>
                {{ userInfo.currency }}{{ number_format(userInfo.total_pay,2) }}
              </strong>
            </td>
            <td class="text-center">
              -
            </td>
          </tr>
          <template v-if="hasExtraPayment">
            <tr
              v-for="(extra, index) of extraPaymentList"
              :key="index"
            >
              <td>
                <v-select
                  v-model="extra.category"
                  :options="extraPaymentCategoryOptions"
                  :reduce="category => category.value"
                  :clearable="false"
                />
              </td>

              <td>
                <validation-provider
                  #default="{ errors }"
                  name="allocation"
                  rules="required"
                >
                  <v-select
                    v-model="extra.payrollWalletUid"
                    :options="allocationsList"
                    :reduce="allocation => allocation.value"
                    :placeholder="$t('payments.select-allocation')"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </td>

              <td
                class="text-right"
                width="96px"
              >
                <b-form-input
                  v-model="extra.amount"
                  type="number"
                  step="any"
                  :placeholder="userInfo.currency"
                />
              </td>
              <td width="200px">
                <b-form-input
                  v-model="extra.remarks"
                  type="text"
                  :placeholder="$t('Remarks')"
                />
              </td>
            </tr>
          </template>
          <tr
            v-for="i of 2"
            :key="i"
          >
            <td colspan="4">
              &nbsp;
            </td>
          </tr>
          <tr class="hide-on-pdf">
            <td colspan="100%">
              <b-button
                v-if="hasExtraPayment"
                v-b-tooltip.hover="$t('payments.add-extra-payment')"
                variant="outline-primary"
                class="btn-icon btn-sm"
                @click="addMoreExtraPayment"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="text-left">
              {{ $t('labels.tax') }}:
            </th>
            <th
              class="text-right"
              colspan="3"
            >
              {{ userInfo.currency }}0.00
            </th>
          </tr>
          <tr>
            <th class="text-left text-lowercase">
              {{ $t('labels.bonus') }}:
            </th>
            <th
              class="text-right"
              colspan="3"
            >
              {{ userInfo.currency }}{{ number_format(totalBonus,2 ) }}
            </th>
          </tr>
          <tr>
            <th class="text-left">
              {{ $t('Total Amount') }}:
            </th>
            <th
              class="text-right"
              colspan="3"
            >
              {{ userInfo.currency }}{{ number_format(totalAmount, 2) }}
            </th>
          </tr>
          <tr>
            <td colspan="100%">
              <b-form-checkbox
                v-model="alreadyPaidInfo.alreadyPaid"
                checked="true"
                name="check-button"
                class="py-1"
                switch
                inline
                @change="() => { alreadyPaidInfo.remarks = '' }"
              >
                {{ $t('payments.already-paid') }}
              </b-form-checkbox>
              <div v-if="alreadyPaidInfo.alreadyPaid">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="remarks"
                >
                  <b-form-group :label="$t('Remarks')">
                    <b-form-input
                      v-model="alreadyPaidInfo.remarks"
                      type="text"
                      :placeholder="$t('Remarks')"
                      @change="$emit('paidInfo', alreadyPaidInfo)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
            </td>
          </tr>
        </tfoot>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BButton, BFormCheckbox, BFormGroup, BTableSimple, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import useApollo from '@/plugins/graphql/useApollo'
import useWallet from '@/utils/wallet'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

const { getWalletIcon } = useWallet()

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BButton,
    BTableSimple,
    ValidationProvider,
    Treeselect,
    vSelect,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
    hasExtraPayment: {
      type: Boolean,
      default: false,
    },
    extraPaymentList: {
      type: Array,
      default: () => [],
    },
    dateRange: {
      type: Object,
      default: () => {},
    },
    addMoreExtraPayment: {
      type: Function,
      required: true,
    },
    extraPaymentCategoryOptions: {
      type: Array,
      default: () => [],
    },
    bankingInformation: {
      type: Object,
      default: () => {},
    },
    generalInformation: {
      type: Object,
      default: () => {},
    },
    basicUserInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      getWalletIcon,
      description: 'Programming Services',
      allocationsList: [],
      altAccountsList: [],
      payrollWalletUid: null,
      alreadyPaidInfo: {
        alreadyPaid: false,
        remarks: '',
      },
      company: this.$store.state.project.selectedCompany,
    }
  },
  computed: {
    totalBonus() {
      if (!this.hasExtraPayment) return 0
      return this.extraPaymentList.reduce((p, c) => p + Number(c.amount), 0)
    },
    totalAmount() {
      return Number(this.totalBonus) + Number(this.userInfo.total_pay, 2)
    },
  },
  watch: {
    alreadyPaidInfo: {
      handler(val) {
        if (this.alreadyPaidInfo.alreadyPaid) {
          this.$emit('paidInfo', {
            alreadyPaid: true,
            remarks: val,
          })
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getCompanyWallets()
  },
  methods: {
    getCompanyWallets() {
      this.getCompanyPayrollWallets()
      this.getCompanyAltWallets()
    },
    getCompanyPayrollWallets() {
      useApollo.company.getCompanyPayrollWallets({
        company: this.company,
      }).then(response => {
        this.allocationsList = response.data.company.wallet.shares.map(share => ({
          label: share.description,
          value: share.uuid,
        }))
        this.payrollWalletUid = this.allocationsList[0].value
        this.$emit('payroll-wallet-updated', this.payrollWalletUid)
      })
    },
    getCompanyAltWallets() {
      useApollo.company.getCompanyAltWallets({
        company: this.company,
      }).then(response => {
        this.altAccountsList = response.data.company.wallets.data.map(({ shares, ...wallet }) => ({
          id: wallet.uuid,
          label: wallet.description,
          children: shares.length ? shares.map(share => ({
            id: share.uuid,
            label: share.description,
            type: 'InternalWallet',
          })) : [],
        }))
      })
    },
    getWalletFromTree(node) {
      const wallet = this.altAccountsList.find(account => account.children.find(child => child.id === node.id))
      const sourceWallet = {
        walletUid: wallet.id,
        shareUid: node.id,
      }

      this.$emit('source-updated', sourceWallet)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/components/treeselect.scss";
</style>
